import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import SwaggerUI from "swagger-ui-react";
import Header from "../utils/Header";
import "swagger-ui-react/swagger-ui.css";
import Editor from "@monaco-editor/react";
import { AppContext, prod, domain } from "../utils/AppContext";


export default function SpecEditor() {
  const location = useLocation();
  const [spec, setSpec] = useState(location.state);
  const { userData, setUserData } = useContext(AppContext);
  const saveSpec = async (spec) => {
    const credentials = JSON.parse(localStorage.getItem("wos-session"));
    // call backend api to save spec
    const url = `${domain}/api/update-user`;
    const data = {
      ...credentials,
      config: {spec: JSON.stringify(spec)},
      hashed: false
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include"
    });
    // console.log(response);
    setUserData({...userData, spec: JSON.stringify(spec) })
  }

  const updateSpec = (value, event) => {
    try {
      let newSpec = JSON.parse(value);
      setSpec(newSpec);
    } catch(error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="text-white " style={{background: "#212121"}}>
      <Header />
      <div className="mx-20 pt-10">  
        </div>
        <div className="flex justify-end mx-20">
          <div className="flex items-center bg-white p-2 rounded hover:bg-gray-200">
            <button
              className="text-black  font-medium py-1 px-4 rounded flex items-center"
              onClick={() => saveSpec(spec)}
            >
              <img
                src="https://www.svgrepo.com/show/309930/save.svg"
                alt=""
                className="h-6 w-6"
              />
              <p className="md:block hidden ml-2">Save</p>
            </button>
          </div>
        </div>
      </div>
      <div className="w-screen h-screen flex flex-row">
        <div
          className="w-[50%] h-[100%] overflow-y-scroll p-4 bg-gray-100"
          style={{ backgroundColor: "black" }}
        >
          <Editor
            theme="vs-dark"
            height="100vh"
            defaultLanguage="javascript"
            defaultValue={JSON.stringify(spec, null, 2)}
            onChange={updateSpec}
          />
          ;
        </div>
        <div className="w-[50%] h-[100%] overflow-y-scroll">
          <SwaggerUI spec={spec} />
        </div>
      </div>
    </>
  );
}
