import React, { useState, useEffect } from "react";
import Header from "../utils/Header";
import { useNavigate } from "react-router-dom";
import { prod, AppContext, domain } from "../utils/AppContext";


const UploadPage = () => {
  const [fileContents, setFileContents] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   (async () => {
  //     const response = await fetch(`${domain}/api/check-auth`, {
  //       method: "GET",
  //       credentials: "include",
  //     });
  //     if (response.status === 401) {
  //       const data = await response.json();
  //       window.location.href = data.redirectTo;
  //     } else {
  //       const data = await response.json();
  //       console.log(data);
  //     }
  //   })();
  // }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const contents = JSON.parse(event.target.result);
        setFileContents(contents);
      };
      reader.readAsText(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    handleFileChange(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileContents) {
      const url = `${domain}/api/update-user`;
      const data = {
        config: { spec: JSON.stringify(fileContents) },
      };
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data),
      });
      navigate("/select");
    }
  };

  return (
    <div className="h-screen text-white" style={{ background: "#212121" }}>
      <Header />
      <div className="mx-20 pt-10">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center justify-center w-full"
          encType="multipart/form-data"
        >
          <label
            className={`flex flex-col items-center justify-center w-full h-64 border-2 ${isDragOver ? "border-blue-300" : "border-gray-300"} border-dashed rounded-lg cursor-pointer hover:opacity-40`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">API Doc (OpenAPI, Swagger)</p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleFileChange}
            />
          </label>
          <div className="flex mt-4">
            <button
              type="button"
              className="mr-4 bg-white text-black px-4 py-2 rounded-md hover:bg-gray-200"
              onClick={handleSubmit} // Call handleButtonClick when button is clicked
            >
              Upload
            </button>
            <button
              type="button"
              className="bg-white text-black px-4 py-2 rounded-md hover:bg-gray-200"
              onClick={() => navigate("/select")}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadPage;
