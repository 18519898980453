// App.js

import React from "react";
import { BrowserRouter as HashRouter, Route, Routes, Navigate } from "react-router-dom";
import UploadPage from "./components/Upload";
import EditPage from "./components/Edit";
import Select from "./components/Select";
import Setup from "./components/Setup";
import SpecEditor from "./components/Editor";
import Settings from "./components/Settings";
import Documentation from "./components/Documentation";
import Login from "./components/Login";
import { AppProvider } from "./utils/AppContext";

function App() {
  return (
    <AppProvider>
      <HashRouter>
        <Routes>
          <Route path="/upload" element={<UploadPage />} />
          {/* <Route path="/edit" element={<EditPage />} /> */}
          <Route path="/select" element={<Select />} />
          <Route path="/editor" element={<SpecEditor />} />
          <Route path="/setup" element={<Setup />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </HashRouter>
    </AppProvider>
  );
}

export default App;
