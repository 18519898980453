import { useState, useContext, useEffect } from "react";
import { AppContext, prod, domain } from '../utils/AppContext';


export default function Login() {
  useEffect(() => {
    (async () => {
      const url = `${domain}/api/auth`
      const response = await fetch(url, {
        method: "GET",
      });
      const data = await response.json();
      console.log(JSON.stringify(data));
      window.location.href = data.authorizationUrl; 
    })();
  }, []);

  

  return (

    <>
      <section className="py-16 xl:pb-56 overflow-hidden h-screen "style={{background: "#212121"}}>
        <div className="container px-4 mx-auto">
          <div className="text-center max-w-md mx-auto">
            <a className="mb-36 inline-block" href="#">
              <img
                src="https://framerusercontent.com/images/DtuIHeWFVWD0PUH9uf5QneEBxE.png"
                alt=""
              />
            </a>
            <h2 className="mb-4 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight text-white">
              Log In
            </h2>
            <p className="mb-12 font-medium text-lg leading-normal text-white">
              Easier ways to collaborate, publish, and get more out of
              integrations for your users.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
