import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { WorkOS } from '@workos-inc/node'; // This is typically not used directly on the client-side.

// change again on real deploy
// const workos = new WorkOS('sk_test_a2V5XzAxSFYyMVhIMkI0SE1RUFRLSE0xVkE0QkFELHYwRlFrckdzYXlJSklQY2hESldPMFJTVFQ');

export default function Header() {
  const [logoutUrl, setLogoutUrl] = useState('#');

  // useEffect(() => {
  //   // Placeholder for the logic to retrieve the session ID. Adjust according to your storage solution.
  //   // This could be a cookie, local storage, or any other method you use to store session IDs.
  //   const sessionId = localStorage.getItem('wos-session'); // Example for local storage. Adjust if using cookies or other methods.

  //   if (sessionId) {
  //     // Construct the logout URL dynamically. In a real-world scenario, you'd call your backend to get this URL.
  //     const authorizationUrl = workos.userManagement.getLogoutUrl({
  //       sessionId: sessionId,
  //     });
  //     setLogoutUrl(authorizationUrl);
  //   }
  // }, []);

  return (

      <nav className=" border-gray-700 shadow-md round" style={{background: "#171717"}}>
  <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
    <Link to="/select" className="flex items-center space-x-3 rtl:space-x-reverse">
   
      <img src=" /logo.svg" className="h-8 object-contain" alt="Logo" />
      <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">Portway</span>
    </Link>
    <div className="flex-grow text-right">
      <Link to="/settings" className="text-white text-lg font-semibold px-2">Settings</Link>
      <Link to="/documentation" className="text-white text-lg font-semibold px-2">Docs</Link>
      <Link to="/setup" className="text-white text-lg font-semibold px-2">Setup</Link>
      <Link to={logoutUrl} className="text-white text-lg font-semibold px-2">Log out</Link>
      <Link to="/upload"><button><p className="md:block hidden ml-2 bg-white text-black font-semibold px-2 rounded px-3 py-1">Add Spec</p></button></Link>
    </div>
   {/* <button data-collapse-toggle="navbar-hamburger" type="button" className="inline-flex items-center justify-center p-2 w-10 h-10 text-sm text-gray-500 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 dark:text-gray-400" aria-controls="navbar-hamburger" aria-expanded="false">
      <span className="sr-only">Open main menu</span>
      <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
      </svg>
    </button>
    <div className="hidden w-full" id="navbar-hamburger">
      <ul className="flex flex-col mt-4 rounded-lg bg-gray-800">
        <li>
          <Link to="#" className="block py-2 px-3 text-white rounded">Home</Link>
        </li>
        <li>
          <Link to="#" className="block py-2 px-3 text-white rounded hover:bg-gray-700">Services</Link>
        </li>
        <li>
          <Link to="#" className="block py-2 px-3 text-white rounded hover:bg-gray-700">Pricing</Link>
        </li>
        <li>
          <Link to="#" className="block py-2 px-3 text-white rounded hover:bg-gray-700">Contact</Link>
        </li>
      </ul>
    </div>*/}
  </div>
</nav>


  );
}





