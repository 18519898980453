import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { domain } from "../utils/AppContext";
import Header from "../utils/Header";
import _ from "lodash";


const DeleteDropDown = ({ deleteEndpoint }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div className="w-full py-2 pb-2">
      <div className="inline-block px-2">
        <button
          type="button"
          className="items-center p-4 text-sm font-medium text-center   focus:ring-8 hover:ring-8 ring-white focus:outline-none"
          onClick={toggleDropdown}
        >
          <svg
            className="w-2.5 h-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 4 15"
          >
            <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
          </svg>
        </button>

        {isOpen && (
          <div className="origin-top-right mt-2 w-44 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
            <ul
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-red-500"
                  onClick={() => {
                    deleteEndpoint();
                    closeDropdown();
                  }}
                >
                  Delete
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700"
                  onClick={closeDropdown}
                >
                  Cancel
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const PlatformDropdown = ({ data, method, path, setMethod }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [method, setMethod] = useState(data.get ? "Trigger" : "Action");

  useEffect(() => {
    if (!method[path] && !data[path]) {
      if (data.get) {
        changeMethod("Trigger");
      } else {
        changeMethod("Action");
      }
    }
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const changeMethod = (newMethod) => {
    setMethod((prev) => {
      return {
        ...prev,
        [path]: newMethod,
      };
    });
    closeDropdown();
  };

  return (
    <div className="w-full py-2 pb-2">
      <div className="inline-block">
        <button
          type="button"
          className="text-black bg-white focus:ring-2 focus:outline-none focus:ring-white font-medium text-sm px-5 py-2.5 text-center inline-flex items-center rounded"
          onClick={toggleDropdown}
        >
          {method[path]}
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        {isOpen && (
          <div className="mt-2 w-44 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <ul
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 "
                  onClick={() => changeMethod("Trigger")}
                >
                  Trigger
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 "
                  onClick={() => changeMethod("Action")}
                >
                  Action
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const AuthDropdown = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [method, setMethod] = useState(data.get ? "No Auth" : "No Auth");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const changeMethod = (newMethod) => {
    setMethod(newMethod);
    closeDropdown();
  };

  return (
    <div className="w-full py-2 pb-2">
      <div className="inline-block">
        <button
          type="button"
          className="text-black bg-white focus:ring-2 focus:outline-none focus:ring-white font-medium text-sm px-5 py-2.5 text-center inline-flex items-center rounded"
          onClick={toggleDropdown}
        >
          {method}
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        {isOpen && (
          <div className=" mt-2 w-44 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <ul
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 "
                  onClick={() => changeMethod("No Auth")}
                >
                  No Auth
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 "
                  onClick={() => changeMethod("User/Password")}
                >
                  User/Password
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 "
                  onClick={() => changeMethod("OAuth 2.0")}
                >
                  OAuth 2.0
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 "
                  onClick={() => changeMethod("API Key")}
                >
                  API Key
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const Select = () => {
  // break between tio fomain
  function getDomain(website) {
    return "https://salesflare.com";
    if (!website.includes("http")) {
      website = "https://" + website;
    }
    const url = new URL("", website);
    return url.hostname;
  }

  function getTopDomain(paths) {
    //console.log(paths);
    if (!paths) return "";
    // topDomain = getTopDomain(Object.keys(temp.paths));
    const freq = {};
    let max = 0;
    let topDomain = "";

    for (let path of paths) {
      const data = spec.paths[path];
      if (!data) continue;

      const reqMethod = Object.keys(data)[0];

      const domain = getDomain(getHostname(data, reqMethod));
      freq[domain] = (freq[domain] || 0) + 1;

      if (freq[domain] > max) {
        max = freq[domain];
        topDomain = domain;
      }
    }
    console.log(topDomain);
    return topDomain;
  }

  const [spec, setSpec] = useState({ paths: {} });

  const [topDomain, setTopDomain] = useState("");
  const [auth, setAuth] = useState({});
  const [method, setMethod] = useState({});
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  const saveSpec = async (spec) => {
    // call backend api to save spec
    const url = `${domain}/api/update-user`;
    const data = {
      config: { spec: JSON.stringify(spec) },
    };
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include"
    });
  };

  const pushToMake = async () => {
    const url = `${domain}/api/push-to-make`;
    const data = {
      spec: spec,
      key: userData.makeKey,
      auth: auth,
      method: method,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include"
    });
    console.log(response);
  };

  const createSDK = async () => {
    const url = `${domain}/api/create-sdk`;
    const data = {
      key: userData.githubKey,
      spec: spec
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include"
    });
    console.log(response);
    const responseBody = await response.json(); // Get the response body as JSON
    // Check if the response is successful
    if (response.ok) {
      // Assuming the response body contains the link
      const link = responseBody.link;

      // Open the link in another tab
      window.open(link, "_blank");
    } else {
      // Handle errors if needed
      console.error("Error:", response.status, responseBody.error);
    }
  };

  const generateDescriptions = async () => {
    const url = `${domain}/api/generate-descriptions`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(spec),
      credentials: "include"
    });
    const data = await response.json();
    const newSpec = JSON.parse(data.spec);
    setSpec(JSON.parse(data.spec));
    saveSpec(newSpec);
  };

  const pushToZapier = async () => {
    const url = `${domain}/api/bind`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        spec: spec,
        method,
        deployKey: userData.zapierKey
      }),
      credentials: "include"
    });
    console.log(response);
  };
  const deleteNonOriginEndPoints = () => {
    const tmpSpec = { ...spec };
    Object.keys(spec.paths).forEach((path) => {
      const data = spec.paths[path];
      const reqMethod = Object.keys(data)[0];
      if (getDomain(getHostname(data, reqMethod)) !== topDomain) {
        delete tmpSpec.paths[path];
      }
    });
    setSpec(tmpSpec);
    setUserData({ ...userData, spec })
    saveSpec(tmpSpec);
  };

  const inferAuth = (spec) => {
    const components = spec.components;
    let schemes = {};
    if (components) {
      schemes = spec.components.securitySchemes;
    } 
    const authTypes = {};
    // for each path, infer auth type
    for (const path of Object.keys(spec.paths)) {
      const pathData = spec.paths[path];
      // if no security section, default to apiKey auth
      if (!spec.security || spec.security.length == 0) {
        authTypes[path] = {
          type: "apiKey",
          in: "header",
          name: "Authorization",
        };
      } else {
        // look at security section and reference schemes to infer auth
        const sec =
          pathData.security || pathData[Object.keys(pathData)[0]].security;
        if (sec) {
          for (const secType of sec) {
            const type = Object.keys(secType)[0];
            if (
              schemes[type] &&
              schemes[type].in != "cookie" &&
              schemes[type].name != "COOKIE"
            ) {
              authTypes[path] = schemes[type];
              break;
            }
          }
        } else {
          authTypes[path] = {
            type: "apiKey",
            in: "header",
            name: "Authorization",
          };
        }
        // if non-cookie auth not found, set to apiKey as default
      }
    }
    console.log(authTypes);
    setAuth(authTypes);
  };

  useEffect(() => {
    const makeRequest = async () => {
      const url = `${domain}/api/get-user`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      if (response.status === 401) {
        const data = await response.json();
        window.location.href = data.redirectTo;
      } else {
        const responseData = await response.json();
        setUserData(responseData.user);
        if (responseData.user && responseData.user.spec) {
          const tmpSpec = JSON.parse(responseData.user.spec);
          setSpec(tmpSpec);
          inferAuth(tmpSpec);
        } else {
          setSpec({ paths: {} });
        }
      }
    };
    makeRequest();
  }, []);

  useEffect(() => {
    setTopDomain(getTopDomain(Object.keys(spec.paths)));
  }, [spec]);

  const getHostname = (path, reqMethod) => {
    const description = path[reqMethod].description;
    if (!description) {
      return "https://test.com";
    }
    const tmp = description.split("**Host**: ");
    console.log("HERE");
    console.log(tmp);
    if (tmp) {
      return tmp[0];
    }
    return "https://test.com";
  };

  const Endpoint = ({ path, data, method, setMethod }) => {
    const deleteEndpoint = () => {
      const tmpSpec = { ...spec };
      delete tmpSpec.paths[path];
      setSpec(tmpSpec);
    };

    const reqMethod = Object.keys(data)[0];
    const hostName = getHostname(data, reqMethod);
    return (
      <div className="w-full mb-6">
        <div className="rounded border-2 ">
          <div className="flex border-b-2 ">
            <div className="flex-1 truncate pr-6 p-3">
              <p className=" font-semibold text-2xl text-clip ">{path}</p>
            </div>
            <div className="flex-none border-l-2 border-white">
              <DeleteDropDown deleteEndpoint={deleteEndpoint} />
            </div>
          </div>
          <div className="flex p-3 justify-around ">
            <div className="flex-none mr-4">
              <img
                src={`https://logo.clearbit.com/${hostName}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://framerusercontent.com/images/entlkS87CkdteOS6EBVdAPbiDWk.svg";
                }}
                className="h-20 rounded-sm"
              />
            </div>
            <div className="flex-1 truncate">
              <div>
                <p className="text-xl font-semibold">
                  {data[reqMethod].summary}
                </p>
              </div>
              <div>
                <div className=" flex truncate">
                  <p className="text-gray-300 text-wrap">
                    {data[reqMethod].description}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-none items-center flex justify-center ml-2 ">
              <AuthDropdown data={data} />
            </div>
            <div className="flex-none items-center flex justify-center ml-2 ">
              <PlatformDropdown
                data={data}
                method={method}
                setMethod={setMethod}
                path={path}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="h-screen text-white " style={{background: "#212121"}}>
    <Header />
    <div className="mx-20 pt-10">  
      </div>
      <section className="pt-5 pb-8">
        <div className="container px-6 lg:px-64 mx-auto">
          <div className="flex justify-between">
            <div>
              <p className="text-2xl font-bold">
                Choose Auth and Edit Actions/Triggers
              </p>
            </div>
            <div className=" items-center flex hover:cursor-pointer justify-center">
              <p
                onClick={() => {
                  console.log(spec);
                  navigate("/editor", { state: spec });
                }}
                className="float-right text-center underline"
              >
                Advanced Editor
              </p>
            </div>
          </div>
          <br />
          {/*Action Menu*/}
          <div className="flex justify-between items-center border-solid rounded-md ">
            <div className="flex items-center bg-white p-2 rounded hover:bg-gray-200">
              <button
                className="text-black font-medium py-1 px-2 rounded flex items-center"
                onClick={pushToZapier}
              >
                <img
                  src="https://iconape.com/wp-content/png_logo_vector/zapier.png"
                  alt=""
                  className="h-6 w-6"
                />
                <p className="2xl:block hidden ml-2">
                  Create Zapier Integration
                </p>
              </button>
            </div>
            <div className="flex items-center bg-white p-2 rounded hover:bg-gray-200">
              <button
                className="text-black font-medium py-1 px-2 rounded flex items-center"
                onClick={pushToMake}
              >
                <img
                  src="https://logo.clearbit.com/make.com"
                  alt=""
                  className="h-6 w-6"
                />
                <p className="2xl:block hidden ml-2">Create Make Integration</p>
              </button>
            </div>

            <div className="flex items-center bg-white p-2 rounded hover:bg-gray-200">
              <button
                className="text-black font-medium py-1 px-2 rounded flex items-center"
                onClick={generateDescriptions}
              >
                <img
                  src="https://logo.clearbit.com/openai.com"
                  alt=""
                  className="h-6 w-6"
                />
                <p className="xl:block hidden ml-2">GPT Cleanup</p>
              </button>
            </div>
            <div className="flex items-center bg-white p-2 rounded hover:bg-gray-200">
              <button
                className="text-black  font-medium py-1 px-4 rounded flex items-center"
                onClick={() => saveSpec(spec)}
              >
                <img
                  src="https://www.svgrepo.com/show/309930/save.svg"
                  alt=""
                  className="h-6 w-6"
                />
                <p className="xl:block hidden ml-2">Publish API</p>
              </button>
            </div>
            <div className="flex items-center bg-white p-2 rounded hover:bg-gray-200">
              <button
                className="text-black font-medium py-1 px-2 rounded flex items-center"
                onClick={createSDK}
              >
                <img
                  src="https://logo.clearbit.com/github.com"
                  alt=""
                  className="h-6 w-6"
                />
                <p className="xl:block hidden ml-2">Create SDK</p>
              </button>
            </div>
          </div>
          <br />
          <div className="mx-auto my-4 bg-white h-px w-90"></div>
          <br />
          <div>
            <p className="text-2xl font-semibold">
              Choose Actions and Triggers
            </p>
            <br />
          </div>

          {Object.keys(spec.paths).map((path) => {
            const data = spec.paths[path];
            const reqMethod = Object.keys(data)[0];
            if (getDomain(getHostname(data, reqMethod)) !== topDomain)
              return null;

            return (
              <Endpoint
                path={path}
                data={data}
                method={method}
                setMethod={setMethod}
              />
            );
          })}
          <br />
          <div className="mx-auto my-4 bg-white h-px w-90"></div>

          <div className="flex justify-between">
            <p className="text-2xl font-semibold">Non-origin Endpoints</p>
            <button
              className="text-black bg-white font-medium py-2 px-6 rounded flex items-center hover:bg-gray-200"
              onClick={deleteNonOriginEndPoints}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA-WPUVwCi4r5CEIL4ZKPRF-Ob7nYLR8YdhmZuAbm8eg&s"
                alt=""
                className="h-6 w-6"
              />
              <p className="md:block hidden ml-2">Delete All</p>
            </button>
          </div>
          <br />

          {Object.keys(spec.paths).map((path) => {
            const data = spec.paths[path];
            const reqMethod = Object.keys(data)[0];

            if (getDomain(getHostname(data, reqMethod)) === topDomain)
              return null;
            return (
              <Endpoint
                path={path}
                data={data}
                method={method}
                setMethod={setMethod}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Select;
