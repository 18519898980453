import React, { useState, useEffect } from "react";
import Header from "../utils/Header";
import { useNavigate } from "react-router-dom";
import { prod, AppContext, domain } from "../utils/AppContext";


const Setup = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen text-white " style={{ background: "#212121" }}>
      <Header />
      <br />
      <div className="container px-4 mx-auto">
        {/* Subheading for Video */}
        <div className="flex justify-center">
          <h3 className="text-lg font-semibold text-white mb-2">
            Setup Video
          </h3>
        </div>
        {/* Embedded Video */}
        <div className="flex justify-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/s33L1OaIH0M"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <br />
        <div className="flex flex-wrap justify-center">
          <h2 className="text-lg font-semibold text-white mb-2">
            Generate API Specification Instantly
          </h2>
        </div>
        <br />
        <div className="flex flex-wrap justify-center gap-4">
          {/* Box 1 */}
          <div className="p-6 border-2 border-gray-600 rounded-lg shadow-md max-w-sm w-full">
            <center>
              <a
                href="https://drive.google.com/drive/folders/1MeMG4F45xG0o2nXO3lgnlkvt--K-e4m2?usp=sharing"
                target="_blank"
              >
                <h2 className="text-lg font-semibold text-white mb-2">
                  Manually
                </h2>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/1024px-Google_Chrome_icon_%28February_2022%29.svg.png"
                  alt=""
                  className="h-32 py-2"
                />
              </a>
              <div className="flex-grow">
                <a
                  href="https://drive.google.com/drive/folders/1MeMG4F45xG0o2nXO3lgnlkvt--K-e4m2?usp=sharing"
                  target="_blank"
                ></a>
                <p className="text-white text-sm">
                  <a
                    href="https://drive.google.com/drive/folders/1MeMG4F45xG0o2nXO3lgnlkvt--K-e4m2?usp=sharing"
                    target="_blank"
                  >
                    Chrome Extension (
                  </a>
                  <a href="tutorial.html" className="hover:cursor underline" target="_blank">
                    Tutorial
                  </a>
                  )
                </p>
              </div>
            </center>
          </div>
          {/* Box 2 */}
          <div className="border-2 border-gray-600 p-6 rounded-lg shadow-md max-w-sm w-full">
            <center>
              <a href="https://docs.akita.software/docs/docker-1" target="_blank">
                <h2 className="text-lg font-semibold text-white mb-2">
                  With Network Traffic
                </h2>
                <img
                  src="https://variwiki.com/images/a/a1/Docker.png"
                  alt=""
                  className="h-32 py-2"
                />
              </a>
              <div className="flex-grow">
                <a
                  href="https://docs.akita.software/docs/docker-1"
                  target="_blank"
                ></a>
                <p className="text-white text-sm">
                  <a
                    href="https://docs.akita.software/docs/docker-1"
                    target="_blank"
                  >
                    Docker (
                  </a>
                  <a href="https://docs.akita.software/docs/docker-1" className="hover:cursor underline" target="_blank">
                    Tutorial
                  </a>
                  ) - Akita while we develop our own
                </p>
              </div>
            </center>
          </div>
        </div>
        {/* Next Steps Button */}
        <div className="mt-6 flex justify-center">
          <button className="bg-white text-black px-4 py-2 rounded-md hover:bg-gray-200 " onClick={() => navigate("/upload")}>
            Next Steps
          </button>
        </div>
      </div>
    </div>
  );
};
export default Setup;
