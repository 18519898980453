import Header from "../utils/Header";
import { useState, useContext } from 'react';
import { domain } from "../utils/AppContext";



const Settings = () => {
  const [zapierKey, setZapierKey] = useState("");
  const [makeKey, setMakeKey] = useState("");
  const [githubKey, setGithubKey] = useState("");

  const handleSave = async () => {
    const url = `${domain}/api/update-user`;
    let config = {};
    if (zapierKey) {
      config.zapierKey = zapierKey;
    }
    if (makeKey) {
      config.makeKey = makeKey;
    } 
    if (githubKey) {
      config.githubKey = githubKey;
    } 
    const data = {
      config: config,
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include"
    });
  }

  return (
    <div className=" text-white " style={{background: "#212121"}}>
    <Header />
    <div className="mx-20 pt-10">  
      </div>
      <section className="pt-5 pb-8">
        <div className="container px-6 lg:px-64 mx-auto">
          <div>
            <p className="text-2xl font-bold">Let's get you started</p>
            <p className="text-gray-300">
              Before we let you use the extension we will need some information
              to speed up the publication process for platforms like Zapier,
              Make, and Workato.
            </p>
            {/* 1. Deploy Keys Section */}
            <section className="mt-8">
              <h2 className="text-lg font-bold mb-4">1. Deploy Keys</h2>
              <ul className="list-disc list-inside">
                <li>
                  This ensures we can create integrations automatically for you.
                </li>
                <li>
                  Where can I find my deploy key? Here are where they are
                  located for{" "}
                  <a
                    href="https://developer.zapier.com/partner-settings/deploy-keys"
                    className="underline text-blue-500 font-semibold"
                    target="_blank"
                  >
                    {" "}
                    Zapier
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.make.com/en/api-documentation/authentication-token"
                    className="underline text-blue-500 font-semibold"
                    target="_blank"
                  >
                    Make
                  </a>
                  . Make sure to get your Github access key as well for SDKs! Ping aidan@portway.ai if help is needed.
                </li>
              </ul>
            </section>
            {/* 2. Service Accounts Section */}
            <section className="mt-8">
              <h2 className="text-lg font-bold mb-4">2. Service Accounts</h2>
              <ul className="list-disc list-inside">
                <li>
                  All access accounts are mandated by us and the{" "}
                  <a
                    href="https://platform.zapier.com/publish/integration-publishing-guidelines"
                    className="text-blue-500 hover:underline font-semibold"
                  >
                    Zapier
                  </a>{" "}
                  platform.
                </li>
                <li>
                  This ensures we can test your application thoroughly for the
                  features you want published as integrations.
                </li>
                <li>
                  Make sure if you are making integrations with features that
                  would require a paying user on your website - that you give
                  our service account access to those paid features.
                </li>
              </ul>
              <br />
              {/* Add more elements as needed */}
            </section>
            <section className="py-3">
              <div className="container px-4 mx-auto border-white border rounded">
                <div className="p-8  rounded-xl">
                  <div className="flex flex-wrap items-center justify-between -mx-4 mb-8 pb-6 border-b  border-opacity-20">
                    <div className="w-full sm:w-auto px-4 mb-8 sm:mb-0">
                      <h4 className="text-2xl font-bold tracking-wide text-white mb-1">
                        Setup Info
                      </h4>
                    </div>
                    <div className="w-full sm:w-auto px-4">
                      <div>
                        <button
                          className="inline-block py-2 px-4 text-xs text-center font-semibold leading-normal text-black bg-white  rounded transition duration-200"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <form action="">
                    <div className="flex flex-wrap items-start -mx-4 pb-8 mb-8 border-b border-gray-400 border-opacity-20">
                      <div className="w-full sm:w-1/3 px-4 mb-8 sm:mb-0">
                        <span className="block mt-5 text-sm font-medium text-gray-100">
                          Deploy Keys
                        </span>
                      </div>
                      <div className="w-full sm:w-2/3 px-4">
                        <div className="max-w-xl">
                          <div className="flex flex-wrap -mx-4 -mb-10">
                            <div className="w-full px-4 mb-10">
                              <div className="relative w-full h-14 py-4 px-3 border border-gray-400 hover:border-white  rounded">
                                <span className="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-300 px-1 bg-gray-500">
                                  Zapier
                                </span>
                                <input
                                  className="block w-full outline-none bg-transparent text-gray-50 placeholder-gray-50 font-semibold"
                                  id="accountInput1-1"
                                  type="text"
                                  value={zapierKey}
                                  onChange={e => setZapierKey(e.target.value)}
                                  placeholder=""
                                />
                                <div
                                  data-lastpass-icon-root="true"
                                  style={{
                                    position: "relative !important",
                                    height: "0px !important",
                                    width: "0px !important",
                                    float: "left !important",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-full px-4 mb-10">
                              <div className="relative w-full h-14 py-4 px-3 border border-gray-400 hover:border-white focus rounded">
                                <span className="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-300 px-1 bg-gray-500">
                                  Make
                                </span>
                                <input
                                  className="block w-full outline-none bg-transparent text-gray-50 placeholder-gray-50 font-semibold"
                                  id="accountInput1-3"
                                  type="text"
                                  value={makeKey}
                                  placeholder=""
                                  onChange={e => setMakeKey(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="w-full px-4 mb-10">
                              <div className="relative w-full h-14 py-4 px-3 border border-gray-400 hover:border-white focus rounded">
                                <span className="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-300 px-1 bg-gray-500">
                                  Github
                                </span>
                                <input
                                  className="block w-full outline-none bg-transparent text-gray-50 placeholder-gray-50 font-semibold"
                                  id="accountInput1-3"
                                  type="text"
                                  value={githubKey}
                                  placeholder=""
                                  onChange={e => setGithubKey(e.target.value)}
                                />
                              </div>
                            </div>
                            <br /> <br />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap items-start -mx-4">
                      <div className="w-full sm:w-1/3 px-4 mb-8 sm:mb-0">
                        <span className="block mt-5 text-sm font-medium text-gray-100">
                          Service accounts
                        </span>
                      </div>
                      <div className="w-full sm:w-2/3 px-4">
                        <div className="max-w-xl">
                          <div className="flex flex-wrap -mx-4 -mb-10">
                            <div className="w-full md:w-1/2 px-4 mb-10">
                              <div className="relative w-full h-14 py-4 px-3 border border-gray-400  rounded">
                                <span className="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-300 px-1 bg-gray-500">
                                  Zapier Email
                                </span>
                                <input
                                  className="block w-full outline-none bg-transparent text-gray-50 placeholder-gray-50 font-semibold"
                                  id="formInput2-10"
                                  type="text"
                                  defaultValue="integration-testing@zapier.com"
                                  disabled=""
                                />
                              </div>
                            </div>
                            <div className="w-full md:w-1/2 px-4 mb-10">
                              <div className="relative w-full h-14 py-4 px-3 border border-gray-400 hover:border-white rounded">
                                <span className="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-300 px-1 bg-gray-500">
                                  New password
                                </span>
                                <input
                                  className="block w-full outline-none bg-transparent text-gray-50 placeholder-gray-50 font-semibold"
                                  id="formInput2-10"
                                  type="text"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="w-full md:w-1/2 px-4 mb-10">
                              <div className="relative w-full h-14 py-4 px-3 border border-gray-400 rounded">
                                <span className="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-300 px-1 bg-gray-500">
                                  Portway.ai Email
                                </span>
                                <input
                                  className="block w-full outline-none bg-transparent text-gray-50 placeholder-gray-50 font-semibold"
                                  id="formInput2-10"
                                  type="text"
                                  defaultValue="aidan@portway.ai"
                                  disabled=""
                                />
                              </div>
                            </div>
                            <div className="w-full md:w-1/2 px-4 mb-10">
                              <div className="relative w-full h-14 py-4 px-3 border border-gray-400 hover:border-white rounded">
                                <span className="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-300 px-1 bg-gray-500">
                                  New password
                                </span>
                                <input
                                  className="block w-full outline-none bg-transparent text-gray-50 placeholder-gray-50 font-semibold"
                                  id="formInput2-10"
                                  type="text"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
            {/*end*/}
          </div>
        </div>
      </section>
    </div>
  );
};
export default Settings;
