import React from "react";
import Header from "../utils/Header";

const Documentation = () => (
  <div className="h-screen text-white " style={{background: "#212121"}}>
  <Header />
  <div className="mx-20 pt-10">  
    </div>

    <div className="flex justify-center items-center h-full overflow-y-clip">
    <iframe
      src="https://docs.google.com/document/d/e/2PACX-1vQc1j4kYih1WU5kNHcVUPvN-Taqujjih77-erpgn596sm2D3JB-3jlvFKxfxIps28U7eNBgvUESnfkA/pub?embedded=true"
      className="md:w-1/2 w-full md:lg-0 mt-32 h-full overflow-y-scroll mx-auto"
    ></iframe>
    </div>
  </div>
  
);

export default Documentation;
