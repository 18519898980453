// AppContext.js
import React, { createContext, useState } from "react";

const AppContext = createContext();

const prod = true;
const domain = prod ? "https://54.183.218.191" : 'http://localhost:3001';

const AppProvider = ({ children }) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const [userData, setUserData] = useState({});

  return (
    <AppContext.Provider
      value={{ credentials, setCredentials, userData, setUserData }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext, prod, domain };
